@import "~react-image-gallery/styles/css/image-gallery.css";
@import url("https://fonts.googleapis.com/css2?family=Italianno&display=swap");
html::-webkit-scrollbar {
  width: 0;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#scrollpath {
  position: fixed;
  top: 0;
  right: 0;
  width: 6px;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
}
#progressbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 6px;
  height: 100%;
  background: linear-gradient(to top, rgb(102, 23, 206), #008aff, #00ffe7);
  animation: scrollanimate 3s linear infinite;
}
@keyframes scrollanimate {
  0%,
  100% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(20deg);
  }
}

.animateme2 {
  animation: animate2 10s linear infinite;
}
.animateme1 {
  animation: animate1 15s linear infinite;
}
.animateme3 {
  animation: animate3 17s linear infinite;
}
.animateme4 {
  animation: animate4 10s linear infinite;
}
.animatehand {
  animation: animate4 2s !important;
}
@keyframes animate1 {
  0% {
    transform: scale(0.5);
  }
  25% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(0.5);
  }
}
@keyframes animate2 {
  0% {
    transform: scale(0.5);
  }
  25% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(0.5);
  }
}
@keyframes animate3 {
  0% {
    transform: scale(0.5);
  }
  25% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(0.5);
  }
}
@keyframes animate4 {
  0% {
    transform: scale(0.7);
  }
  25% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(0.7);
  }
}

@keyframes animate5 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.adjustme {
  width: 100% !important;
  height: 100vh !important;
  overflow: hidden !important;
  /* color: rgba(34, 41, 59, 0.815); */
}
.wave {
  animation-name: wave-animation;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.textedit {
  font-family: "Italianno", cursive;
}
#aboutsection {
  width: 100vw;
  /* background-color: #000000; */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23101422' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23202744' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23313b66' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23414e88' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%235162AA' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
#projectsection {
  width: 100vw;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%230050cc' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23494aaa' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%234061c6' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%234049a3' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%235973bf' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%2336489c' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%236b85b8' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%232b4795' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%237997b0' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%231e468f' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%2385aaa8' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%230c4588' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.setTerminal {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.aboutcircle1 {
  animation: animate6 5s linear infinite;
}
@keyframes animate6 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50px);
  }
  70% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0px);
  }
}
.aboutcircle2 {
  animation: animate7 2s linear infinite;
}
@keyframes animate7 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50px);
  }
  70% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0px);
  }
}

.modaldesign {
  overflow: hidden !important;
}
.projectedit {
  border-top: 4px solid rgb(10, 172, 172);
  transition: 0.2s ease-in-out;
}
.projectedit:hover {
  border-top: 0;
  box-shadow: 7px 7px rgb(74, 154, 165) !important;
  transform: scale(1.05);
  /* color: #03df6a; */
}
.footeredit {
  width: 100vw;
  border-bottom: 3px solid rgb(148, 148, 148);
  clip-path: polygon(0 44%, 100% 0, 100% 100%, 0% 100%);
  overflow: hidden !important;
}

.headerstack {
  width: 100vw;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)),
    url("download.png");
  background-attachment: fixed;
  background-size: cover;
}

#background-video {
  position: absolute;
  height: 100%;
  width: 100%;
  float: left;
  top: 0;
  left: 0;
  padding: none;
  /* optional depending on what you want to do in your app */
}

/* canvas {
        background: 
          linear-gradient(
            hsl(200, 50%, 80%) 0%, 
            hsl(200, 30%, 95%) 75%)
        ;
        display: block;
      }
      
      .bgvid {
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        background: url(https://jackrugile.com/images/misc/skyline-texture.png);
      } */

.about-us {
  position: relative;

  width: 100%;
  /* background-color: #f5f5f5; */
  /* padding-bottom: 15rem; */
}

.section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 0 10rem 0;
}

.section-heading {
  font-family: "Muli", serif;
  font-size: 5rem;
  font-weight: 300;
  color: #4b4b4b;
  margin-bottom: 6rem;
}

.underline {
  width: 12rem;
  height: 0.3rem;
  background-color: #c29525;
}

.services {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(6, 6rem);
  grid-row-gap: 4rem;
}

.service {
  width: 100%;
  margin-bottom: 2rem;
}

.service:nth-child(1) {
  grid-column: 4 / 7;
  grid-row: 1 / 3;
}

.service:nth-child(2) {
  grid-column: 3 / 6;
  grid-row: 3 / 5;
}

.service:nth-child(3) {
  grid-column: 4 / 7;
  grid-row: 5 / -1;
}

.service:nth-child(4) {
  grid-column: 11 / 14;
  grid-row: 1 / 3;
}

.service:nth-child(5) {
  grid-column: 12 / 15;
  grid-row: 3 / 5;
}

.service:nth-child(6) {
  grid-column: 11 / 14;
  grid-row: 5 / -1;
}

.service-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.service-header i {
  font-size: 4rem;
  color: #4b4b4b;
  margin-right: 2rem;
}

.service-header h3 {
  font-family: "Baloo Da 2", serif;
  font-size: 2.6rem;
  line-height: 2.6rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

.service-text {
  font-family: "Josefin Slab", serif;
  font-size: 1.6rem;
  text-align: justify;
}

.about-us-img-wrapper {
  grid-column: 7 / 11;
  grid-row: 2 / 6;
  width: 100%;
}

.about-us-img-wrapper img {
  width: 100%;
  object-fit: cover;
  opacity: 0.8;
}

.wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.slider {
  width: 80vw;
  height: 100px;
  position: relative;
  /* background: white; */
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
  display: flex;
  overflow: hidden;
}
.slide {
  height: 100px;
  display: flex;
  align-items: center;
  animation: slideshow 8s linear infinite;
  border-radius: 30px !important;
}
.slide img {
  height: 70px;
  padding: 0 30px 0 30px;
  border-radius: 30px !important;
}
@keyframes slideshow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.slider::before,
.slider::after {
  height: 100px;
  width: 200px;
  position: absolute;
  content: "";
  /* background: linear-gradient(to right, white 0%, rgba(255,255,255,0) 100%); */
  z-index: 2;
}
.slider::before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.footer2 {
  position: relative;
  clip-path: polygon(0 0, 100% 13%, 100% 100%, 0% 100%);
  overflow: hidden;
}
.editfoot {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.068);
  z-index: -1;
  transform: rotate(30deg) !important;
}
.editfoot2 {
  position: absolute;
  top: 0;
  right: 100px;
  width: 100%;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.075);
  z-index: -1;
  transform: rotate(30deg) !important;
}

.glass-card-effect {
 /* From https://css.glass */
background: rgba(11, 153, 208, 0.07);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(9.4px);
-webkit-backdrop-filter: blur(9.4px);
border: 1px solid rgba(11, 153, 208, 0.04);
}

.latestpr {
  animation: latestpranim 1s linear infinite;
  border: 2px dotted red;
  margin: 0px 10px;
}
@keyframes latestpranim {
  0% {
    transform: scale(1);
    filter: hue-rotate(0deg);
  }
  50% {
    transform: scale(1.05);
    filter: hue-rotate(270deg);
  }
  100% {
    transform: scale(1);
    filter: hue-rotate(90deg);
  }
}
.animatelogo {
  animation: animlogo 1s linear infinite;
}

@keyframes animlogo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
